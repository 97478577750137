export default function date_sort(a, b) {
  const [dayA, monthA, yearA] = a.split(".");
  const [dayB, monthB, yearB] = b.split(".");

  if (parseInt(yearA) > parseInt(yearB)) {
    return -1;
  }
  if (parseInt(yearB) > parseInt(yearA)) {
    return 1;
  }
  if (parseInt(monthA) > parseInt(monthB)) {
    return -1;
  }
  if (parseInt(monthB) > parseInt(monthA)) {
    return 1;
  }
  if (parseInt(dayA) > parseInt(dayB)) {
    return -1;
  }
  if (parseInt(dayB) > parseInt(dayA)) {
    return 1;
  }
  return 0;
}
