import jQuery from "jquery";
import Rails from "@rails/ujs";
import JSZip from "jszip";

Object.assign(window, {
  $: jQuery,
  jQuery,
  Rails,
  JSZip,
});

try {
  Rails.start();
} catch {}
