import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["calendarSection"];

  connect() {
    console.log("Hello, users controller!");
    this.calendarSection = document.getElementById("calendarSection");

    // If calendarSection exists, ensure it's displayed when the page loads
    if (this.calendarSection) {
      this.calendarSection.style.display = "block";
      const navLinks = document.querySelectorAll(".calendar-heading nav a");

      navLinks.forEach((link) => {
        link.classList.add("btn");
        link.classList.add("btn-primary");
      });
    }
  }
}
