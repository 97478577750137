import { Controller } from "@hotwired/stimulus";
import * as L from "leaflet";

export default class extends Controller {
  connect() {
    super.connect();

    const map = L.map("map", {});
    map.setView([50.135, 8.63], 13);

    L.tileLayer(`${mapboxApiUrl}?access_token=${mapboxAccessToken}`, {
      maxZoom: 19,
      attribution:
        '© <a href="https://www.mapbox.com/feedback/">Mapbox</a>' +
        '© <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
      tileSize: 512,
      zoomOffset: -1,
    }).addTo(map);

    const markers = window.sites.map((site) => {
      return L.circleMarker(site.loc, {
        fillColor: site.color,
        fillOpacity: 0.4,
        radius: 6.5,
        stroke: false,
      }).addTo(map);
    });

    map.on("zoomend", () => {
      const currentZoom = map.getZoom();
      const radius = currentZoom * (1 / 2);
      console.log(`zoom: ${currentZoom} radius: ${radius}`);
      markers.forEach((marker) => {
        marker.setRadius(radius);
      });
    });
  }
}
