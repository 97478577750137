import Rails from "@rails/ujs";
import "rhino-editor/exports/styles/trix.css";
import "rhino-editor";

document.addEventListener("rhino-before-initialize", (e) => {
  const rhinoEditor = e.target;
  rhinoEditor.starterKitOptions = {
    ...rhinoEditor.starterKitOptions,
    rhinoGallery: false,
    rhinoAttachment: false,
  };
});
