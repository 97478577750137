// app/javascript/controllers/cancel_options_controller.js
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "sachverhalttxt",
    "sachverhaltnr",
    "rmg",
    "rmgtxt",
    "vorgangsgrund",
    "vorgangsgrundtxt",
    "sachverhaltfrei",
  ];

  connect() {
    super.connect();
    console.log("cancel_options_controller connected");

    const select_element = document.getElementById("cancel_options_id");
    console.log("selected_element", select_element);

    // const selected_option =
    //   select_element.options[select_element.selectedIndex].value;
    // console.log("selected_option", selected_option);

    // onchange of the select element, update the values of the form fields
    // with the values from the selected option
    select_element.addEventListener("change", (event) => {
      console.log("select_element changed", event.target.value);
      const selected_option = event.target.value;
      this.updateFormFields(selected_option);
    });
  }

  updateFormFields(selected_option) {
    // Define mapping of options to autofill values
    const autofillValues = {
      option_1: {
        sachverhalttxt:
          "Erfolgloser Versuch -> Auftragskopie erzeugen für erneute Disposition",
        sachverhaltnr: "0010",
        rmg: "300",
        rmgtxt: "Auftrag nicht fertig (Wiedervorlage)",
        vorgangsgrund: "",
        vorgangsgrundtxt: "",
        sachverhaltfrei: "",
      },
      option_2: {
        sachverhalttxt:
          "Erfolgloser Versuch -> Auftragskopie erzeugen für erneute Disposition",
        sachverhaltnr: "0030",
        rmg: "300",
        rmgtxt: "Auftrag nicht fertig (Wiedervorlage)",
        vorgangsgrund: "",
        vorgangsgrundtxt: "",
        sachverhaltfrei: "",
      },
      option_3: {
        sachverhalttxt:
          "Erfolgloser Versuch -> Auftragskopie erzeugen für erneute Disposition",
        sachverhaltnr: "0040",
        rmg: "300",
        rmgtxt: "Auftrag nicht fertig (Wiedervorlage)",
        vorgangsgrund: "",
        vorgangsgrundtxt: "",
        sachverhaltfrei: "",
      },
    };

    // Autofill form fields based on the selected option
    const valuesToAutofill = autofillValues[selected_option];
    console.log("valuesToAutofill", valuesToAutofill);
    if (valuesToAutofill) {
      Object.keys(valuesToAutofill).forEach((fieldName) => {
        // Concatenate 'cancellation_' with fieldName
        const targetIdentifier = `cancellation_${fieldName}`;

        // Use native JavaScript to find the element by ID
        const targetElement = document.getElementById(targetIdentifier);

        if (targetElement) {
          // Set the value using native JavaScript
          targetElement.value = valuesToAutofill[fieldName];
        }
      });
    }
  }
}
