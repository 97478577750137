import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["input", "preview", "currentImage"];

  preview() {
    const file = this.inputTarget.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        this.previewTarget.src = e.target.result;
        this.previewTarget.style.display = "block";
        if (this.hasCurrentImageTarget) {
          this.currentImageTarget.style.display = "none";
        }
      };
      reader.readAsDataURL(file);
    }
  }
}
