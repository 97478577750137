// add an eventlistener to the document to initialize the datetimepicker after a simple form is loaded
document.addEventListener("turbo:load", function (event) {
  $(".form_datetime").datetimepicker({
    format: "DD.MM.YYYY HH:mm",
    extraFormats: ["YYYY-MM-DD HH:mm:ss ZZ"],
    locale: "de",
    allowInputToggle: true,
    widgetPositioning: {
      horizontal: "left",
      vertical: "auto",
    },
  });

  $(".form_date").datetimepicker({
    format: "DD.MM.YYYY",
    extraFormats: ["YYYY-MM-DD"],
    locale: "de",
    allowInputToggle: true,
    widgetPositioning: {
      horizontal: "left",
      vertical: "auto",
    },
  });

  $(".form_time").datetimepicker({
    format: "HH:mm",
    extraFormats: ["HH:mm:ss ZZ"],
    locale: "de",
    allowInputToggle: true,
    widgetPositioning: {
      horizontal: "left",
      vertical: "auto",
    },
  });
});
