import { Controller } from "@hotwired/stimulus";
import Combobox from "@github/combobox-nav";
import "@hotwired/turbo-rails";

// This controller allows for keyboard navigation inside the list of options
export default class extends Controller {
  static get targets() {
    return ["input", "list"];
  }

  connect() {
    super.connect();
    console.log("ComboboxController connected");
  }

  disconnect() {
    this.combobox?.destroy();
  }

  listTargetConnected() {
    this.start();

    const searchBox = document.getElementById("search_query");

    // Retrieve the stored search query
    const storedQuery = sessionStorage.getItem("storedSearchQuery");
    if (storedQuery) {
      searchBox.value = storedQuery;
    }

    // Handle search box changes
    searchBox.addEventListener("input", function (event) {
      const query = event.target.value;

      // Store the search query in sessionStorage
      sessionStorage.setItem("storedSearchQuery", query);
    });

    this.listTarget.addEventListener("combobox-commit", function (event) {
      Turbo.visit(event.target.getAttribute("data-url"));
    });
    this.listTarget.addEventListener("click", function (event) {
      Turbo.visit(event.target.getAttribute("data-url"));
    });
  }

  start() {
    this.combobox?.destroy(); // Ensure that the previous instance is destroyed

    this.combobox = new Combobox(this.inputTarget, this.listTarget);
    this.combobox.start();
  }

  stop() {
    this.combobox?.stop();
  }
}
