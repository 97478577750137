import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    super.connect();

    const navbar = this.element;
    const navbarHeight = navbar.offsetHeight;
    const body = document.querySelector("body");
    body.style.paddingTop = `${navbarHeight}px`;
  }
}
