import { Controller } from "@hotwired/stimulus";
import { debounce } from "lodash";
export default class extends Controller {
  static get targets() {
    return ["submit", "dayCheckbox"];
  }
  initialize() {
    this.submit = debounce(this.doSubmit.bind(this), 200);
  }
  connect() {
    super.connect();
    console.log("FormController connected");
    this.submitTarget.hidden = true;
  }

  doSubmit() {
    this.submitTarget.click();
  }
  toggleWeek(event) {
    const weekNumber = event.target.dataset.week;

    const relatedCheckboxes = this.dayCheckboxTargets.filter(
      (cb) => cb.dataset.week === weekNumber
    );

    relatedCheckboxes.slice(0, 5).forEach((cb) => {
      cb.checked = event.target.checked;
    });
  }
}
