// app/javascript/controllers/dropdown_controller.js
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    super.connect();
    console.log("dropdown_controller connected");

    $("#site_appointment_timeslot_id").on("change", function () {
      var selectedValue = $(this).val();

      $("#timeslot_by_date_id").val(selectedValue);
    });

    $("#timeslot_by_date_id").on("change", function () {
      var selectedValue = $(this).val();

      $("#site_appointment_timeslot_id").val(selectedValue);
    });
  }

  change(event) {
    // get the data-target value of this element
    const target = event.target.getAttribute("data-turbo-frame");

    // change the src of the data-turbo-frame
    const frameElement = document.getElementById(target);

    frameElement.setAttribute(
      "src",
      "/sites/nearest_sites/" + event.target.value
    );
  }
}
