import date_sort from "./date_sort";

import setup from "yadcf";

const yadcf = setup(window, window.$);

function yadcf_wrapper(dataTable, columns) {
  const defaultColumn = {
    style_class: "form-control input-sm",
    reset_button_style_class: "btn btn-sm",
  };

  let newColumns = [];

  columns.forEach(function (column, index) {
    let newColumn = {};
    for (let attrname in defaultColumn) {
      newColumn[attrname] = defaultColumn[attrname];
    }
    for (let attrname in column) {
      newColumn[attrname] = column[attrname];
    }
    newColumn["column_number"] = index;

    if (newColumn["filter_type"] == "range_date") {
      newColumn["style_class"] = null;
    }

    if (newColumn["sort_as_custom_func"]) {
      switch (newColumn["sort_as_custom_func"]) {
        case "date_sort":
          newColumn["sort_as_custom_func"] = date_sort;
          break;
        default:
          console.warn(
            "Unknown custom func" + newColumn["sort_as_custom_func"]
          );
          newColumn["sort_as_custom_func"] = undefined;
      }
    }
    newColumns.push(newColumn);
  });
  yadcf.init(dataTable, newColumns);
}

export default yadcf_wrapper;
