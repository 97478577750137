import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    super.connect();
    console.log("ProjectSelectController connected");
  }

  change(_event) {
    var url = new URL(window.location.href);
    url.searchParams.set("filter_project", this.element.value);
    console.log("ProjectSelectController changed to " + this.element.value);
    window.location = url.toString();
  }
}
